import React from "react";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const analytics = Analytics({
    app: 'Ministore',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-184679173-1',
            anonymizeIp: true
        })
    ]
});

function Success(props) {
    const dev = true;
    if (!dev) {

        analytics.page();
    }
    return (
        <div className="success">
            <strong>Takk for at du valgte å handle hos Barbershop.</strong>
            <span>Du vil snart motta en e-post som bekrefter din ordre fra Barbershop. Hvis du har noen spørsmål angående din ordre, vennligst kontakt Barbershop direkte.<br /> <br />Ditt ordrenummer er: <br /><strong>{props.orderId}</strong></span>
        </div>
    );
}

export default Success;
