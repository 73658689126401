import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

function ProductDescription(props) {
    const dev = true;
    const [data, setData] = useState({ title: null, oldPrice: 0, description: null });
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (props.product.product.variants && props.product.product.variants.length) {
            setData({
                title: props.product.product.title,
                price: props.product.product.variants[0].price + ` kr`,
                oldPrice: props.product.product.variants[0].compare_at_price + ` kr`,
                description: props.product.product.body_html
            })
        }
    }, [props.product]);

    const track = () => {

        const analytics = Analytics({
            app: 'Ministore',
            plugins: [
                googleAnalytics({
                    trackingId: 'UA-184679173-1',
                    anonymizeIp: true
                })
            ]
        });
        if (!open) {
            setOpen(true);
            if (!dev) {
                analytics.track('moreAboutProduct', { category: 'Button', label: 'open product details' });
            }
        } else {
            if (!dev) {
                analytics.track('moreAboutProduct', { category: 'Button', label: 'close product details' });
            }
            setOpen(false);

        }

    };
    return (
        <div>
            <div className={'description-container'}>
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading onClick={track}>
                            <AccordionItemButton>
                                OM PRODUKTET
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div dangerouslySetInnerHTML={{
                                __html: data.description
                            }}>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>

    );
}


export default ProductDescription;
