import React from "react";
import creditCard from './assets/credit-card.svg';
import vippsOrange from './assets/vipps-orange.svg';
import returnTruck from './assets/return-truck.svg';

function AdditionalInformation(props) {


    return (
        <div className={'additional-information'}>
            <div className={'row'}>

                <div className={'first-col'}>
                    <img src={creditCard} alt={"SIKKER BETALING MED"} id={"card-logo"}/>SIKKER BETALING MED<img
                    src={vippsOrange} alt={"vipps logo"} id={"vipps-logo"}/>
                </div>
                <div className={'second-col'}>
                    <img src={returnTruck} alt={"GRATIS RETUR"} id={'retur'} />GRATIS RETUR
                </div>
            </div>
        </div>

    );
}


export default AdditionalInformation;
