import React, { useEffect, useState } from "react";
import axios from 'axios';
import header_logo from './assets/barbershop-logo-no-white-600_250x@2x.webp';
import ProductImages from './ProductImages';
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import Success from "./Success";
import { useParams } from "react-router";
import ProductNamePrice from "./ProductNamePrice";
import Cancel from "./Cancel";
import { Motion, spring } from "react-motion";
import shield from "./assets/verified.svg";

function SuccessPage() {
    const { productId, orderId } = useParams();
    const [data, setData] = useState({ product: { images: [], variants: [{ price: 0 }] } });
    const [status, setStatus] = useState({});
    const [openModal, setOpenModal] = useState(false);

    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function verifyStatus() {
            const statusRequest = await verifyPayment(orderId);
            setStatus(statusRequest.data);
        }

        verifyStatus();
    }, [orderId]);

    useEffect(() => {
        async function fetchData() {
            const result = await axios({
                    method: "GET",
                    url: `https://test.api.ministore.helthjem.no/products/${productId}`,
                }
            );
            setData(result.data);
        }

        fetchData();
    }, [productId]);


    const verifyPayment = async (orderId) => {
        const req = await axios({
            method: "POST",
            url: `https://test.api.ministore.helthjem.no/vipps/status/${orderId}`,
        }).catch(() => alert('payment failed'));

        return req;
    };
    const initialErrorStyle = () => {
        return {
            y: spring(295, { stiffness: 190, damping: 25 })
        }
    };
    const finalErrorStyle = () => {
        return {
            y: spring(-45, { stiffness: 190, damping: 25 })
        }
    };
    let modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();
    const handleOpenModal = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    return (
        <div className="ProductPage">
            <header className="ProductHeader"><img src={header_logo} alt={'barbershop logo'} /></header>

            <div className="container">
                <ProductImages images={data.product.images} />
                <ProductNamePrice product={data} />
                <div style={{marginTop: `20px`}}/>
                <AdditionalInformation />
                <span onClick={handleOpenModal} id={'privacy'}><img src={shield} width={15} alt={''}/>Personopplysninger</span>

                <Motion style={modalStyle}>
                    {({ y }) => {
                        return (
                            <div className="modal" style={{ transform: `translateY(${y}px)` }}>
                                Vi gjør oppmerksom på at informasjon vi mottar som følge av at du gjennomfører et kjøp ikke
                                lagres av Schibsted, men av Barbershop som er selger av produktet og trenger denne
                                informasjonen for å sikre at du får en optimal handleopplevelse. <br/><br/>Dine personopplysninger
                                benyttes derfor kun for å gjennomføre handelen og sikre at du får din leveranse til riktig
                                sted og til rett tid. Vi forbeholder oss imidlertid retten til å avvise ordre når vi finner
                                grunn til det.
                                <button onClick={handleCloseModal}>OK</button>
                            </div>);
                    }
                    }
                </Motion>
                {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'SALE' && <Success orderId={orderId} />}
                {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CAPTURE' && <Success orderId={orderId} />}
                {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CANCEL' && <Cancel orderId={orderId} />}
                <ProductDescription product={data} />

            </div>
        </div>
    );
}


export default SuccessPage;
