import React, { useEffect, useState } from "react";
import axios from 'axios';
import header_logo from './assets/barbershop-logo-no-white-600_250x@2x.webp';
import shield from './assets/verified.svg';
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import ShippingDetails from "./ShippingDetails";
import { useParams } from "react-router";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import ProductNamePrice from "./ProductNamePrice";
import ProductImages from "./ProductImages";
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { Motion, spring } from "react-motion";
import ReactModal from 'react-modal';

const override = css`
  display: block;
  margin: 0 auto;
  position:absolute;
  margin-left:auto;
  margin-right:auto;
  left:0;
  right:0;
  width:60px;
  top:16px;
`;

function ProductPage() {
    const dev = true;
    const analytics = Analytics({
        app: 'Ministore',
        plugins: [
            googleAnalytics({
                trackingId: 'UA-184679173-1',
                anonymizeIp: true
            })
        ]
    });
    useEffect(() => {
        if (!dev) {
            analytics.page();
        }

    }, [])


    const api_url = dev ? 'https://test.api.ministore.helthjem.no' : 'https://api.ministore.helthjem.no';
    const { productId, } = useParams();
    const [data, setData] = useState({ product: { images: [], variants: [{ price: 0 }] } });
    const [openModal, setOpenModal] = useState(false);
    const [openPopup, setOpenPopup] = useState(true);
    const [quantity, setNumber] = useState(1);
    const [authorizePaymentInProgress, setAuthorizePaymentInProgress] = useState(false);
    const mapVariants = [{
        oldId: "5fc4d9a74cedfd000dc2e6bf",
        newId: "5386246727",
        name: "Introsett til tradisjonell barbering",
        variantId: 32115078856809
    },
        { oldId: "5fc4da524cedfd000dc2e6c0", newId: "4242984455", name: "R89", variantId: 0 },
        {
            oldId: "5fc4d9cf52faff000e211307",
            newId: "1490082398313",
            name: "Barbersett for henne",
            variantId: 32115541409897
        }
    ];
    const variantId = mapVariants.filter(m => m.oldId === productId);
    const [images, setImages] = useState([]);
    useEffect(() => {

        async function fetchData() {
            const result = await axios({
                    method: "GET",
                    url: `${api_url}/products/${productId}`,
                }
            );
            setData(result.data);
            const variant = variantId[0].variantId;
            if (variant !== 0) {
                const filteredImages = result.data.product.images.filter(img => img.variant_ids.includes(variant) || img.variant_ids.length === 0);
                setImages(filteredImages);
            } else {
                setImages(result.data.product.images);

            }
        }

        fetchData();

    }, [productId]);

    const plus = () => {
        if (!dev) {
            analytics.track('changeQuantity', { category: 'quantity', label: 'plus' });
        }
        if (quantity < 5) {
            setNumber(quantity + 1);
        }
    };
    const minus = () => {
        if (!dev) {
            analytics.track('changeQuantity', { category: 'quantity', label: 'minus' });
        }
        if (quantity > 1) {

            setNumber(quantity - 1);
        }
    };

    const makePayment = async () => {
        setAuthorizePaymentInProgress(true);
        if (!dev) {
            analytics.track('payButtonClicked', { category: 'Vipps Buton', label: 'vipps button clicked' },);
        }
        const req = await axios({
            method: "POST",
            url: `${api_url}/vipps/${productId}`,
            data: {
                price: parseFloat(data.product.variants[0].price),
                title: data.product.title,
                quantity: parseInt(quantity, 10),
                variant_id: data.product.variants[0].id,
                product_id: productId
            }
        }).catch(() => alert('payment failed'));

        if (req && (req.status === 200 || req.status === 201) && !req.hasOwnProperty("errorCode")) {
            window.location = req.data.url;

        } else {
            alert('payment failed');
        }

    };
    const initialErrorStyle = () => {
        return {
            y: spring(295, { stiffness: 190, damping: 25 })
        }
    };
    const finalErrorStyle = () => {
        return {
            y: spring(-45, { stiffness: 190, damping: 25 })
        }
    };
    let modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();
    const handleOpenModal = () => {
        setOpenModal(true);
        if (!dev) {
            analytics.track('modal', { category: 'PrivacyModal', label: 'open' });
        }
    }
    const handleCloseModal = () => {
        if (!dev) {
            analytics.track('modal', { category: 'PrivacyModal', label: 'close' });
        }
        setOpenModal(false);
    }

    const handleClosePopup = () => {

        setOpenPopup(false);
    }
    return (
        <div className="ProductP`age">
            <header className="ProductHeader"><img src={header_logo} alt={'barbershop logo'} /></header>
            <div className="container">
                <ProductImages images={images} />
                <ProductNamePrice product={data} />
                <ProductDescription product={data} />

                <div className={'product-counter'}>
                    ANTALL <button className={'minus'} onClick={minus}>-</button><span
                    className={'current'}>{quantity}</span>
                    <button className={'plus'} onClick={plus}>+</button>
                    {data.product.title && quantity > 1 && <span
                        className={'summary-price'}> (total: {data.product.variants[0].price ? quantity * parseFloat(data.product.variants[0].price) : 0} kr)</span>}
                </div>

                <AdditionalInformation />

                <ShippingDetails />
                <div className={"button-wrapper"}>
                    <button className="button vipps"
                            disabled={!data.product.images.length || authorizePaymentInProgress}
                            onClick={makePayment}>Vipps
                        Hurtigkasse
                    </button>
                    <PulseLoader
                        css={override}
                        size={15}
                        color={"white"}
                        loading={authorizePaymentInProgress}
                    /></div>
                <span onClick={handleOpenModal} id={'privacy'}><img src={shield} width={15} alt={''} />Personopplysninger</span>

            </div>
            <Motion style={modalStyle}>
                {({ y }) => {
                    return (
                        <div className="modal" style={{ transform: `translateY(${y}px)` }}>
                            Vi gjør oppmerksom på at informasjon vi mottar som følge av at du gjennomfører et kjøp ikke
                            lagres av Schibsted, men av Barbershop som er selger av produktet og trenger denne
                            informasjonen for å sikre at du får en optimal handleopplevelse. <br /><br />Dine
                            personopplysninger
                            benyttes derfor kun for å gjennomføre handelen og sikre at du får din leveranse til riktig
                            sted og til rett tid. Vi forbeholder oss imidlertid retten til å avvise ordre når vi finner
                            grunn til det.
                            <button onClick={handleCloseModal}>OK</button>
                        </div>);
                }
                }
            </Motion>
            <ReactModal
                isOpen={openPopup}
            >
                Velkommen til en trygg handel med Schibsted hurtigkasse. <br/><br/>Her kan du trygt handle varer på en enklere måte. Dersom du har spørsmål angående løsningen, send en mail til: <strong>hurtigkasse@schibsted.no</strong>
                <button onClick={handleClosePopup}>OK</button>
            </ReactModal>
        </div>
    );
}


export default ProductPage;
