import React, { useEffect, useState } from "react";

import Skeleton from "react-loading-skeleton";


function ProductNamePrice(props) {
    const [data, setData] = useState({ title: null, oldPrice: 0, description: null });
    useEffect(() => {
        if (props.product.product.variants && props.product.product.variants.length) {
            if (props.product.product.variants[0].compare_at_price) {
                setData({
                    title: props.product.product.title,
                    price: props.product.product.variants[0].price + ` kr`,
                    oldPrice: props.product.product.variants[0].compare_at_price + ` kr`,
                })
            } else {
                setData({
                    title: props.product.product.title,
                    price: props.product.product.variants[0].price + ` kr`,
                    oldPrice: 0,
                })
            }
        }
    }, [props.product]);

    return (
        <div className={"row"}>
            <h1>{data.title || <Skeleton />}</h1>
            <div className={'product-price'}>
                {props.product.product.variants[0].price && <span className={'current-price'}>{data.price}</span> ||
                <Skeleton width={70} />}
                {data.title && data.oldPrice !== 0 && <span className={'old-price'}>{data.oldPrice}</span>}
            </div>
        </div>


    );
}


export default ProductNamePrice;
