import React from "react";
import truck from './assets/delivery-truck.svg';
import helthjem from './assets/Helthjem_RGB_gulsort.svg';

function ShippingDetails() {

    return (
        <div className="shipping-details">
            <div className={'shipping-row row-1'}>
                <img src={truck} alt={'delivery icon'} width={16} id={'delivery-icon'} /> FORSENDELSESMETODE
                <span>gratis levering med <img src={helthjem} alt={'delivery icon'} width={55}
                                               id={'helthem-icon'} /></span>
            </div>
        </div>
    );
}

export default ShippingDetails;
