import React from "react";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
const analytics = Analytics({
    app: 'Ministore',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-184679173-1',
            anonymizeIp: true
        })
    ]
});
function Cancel(props) {

    analytics.page();
    return (
        <div className="cancel">
            <strong>Beklager, men noe gikk galt under behandlingen av din bestilling.</strong><br /><br />
            <span>Vennligst forsøk igjen eller ta kontakt med Barbershop på <strong>hei@barbershop.no</strong></span>
        </div>
    );
}

export default Cancel;
